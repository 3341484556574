<template>
    <nav :style="`--scroll-progress: ${scrollPercentage};`" class="chapters-nav">
        <div class="wrapper">
            <div class="inner">
                <template v-if="isPhone">
                    <div class="phone-panel-left">
                        <div class="phone-controls">
                            <Typography
                                as="button"
                                variant="label-sentence"
                                transform="capitalize"
                                class="phone-subscribe"
                            >
                                {{ $t('subscribe') }}
                            </Typography>

                            <button class="phone-share">
                                <Icon name="share" class="phone-share-icon"></Icon>
                            </button>
                        </div>
                    </div>

                    <div class="phone-panel-right">
                        <button @click="onPhoneTocOpenClick" class="phone-toc-open">
                            <Icon name="table-of-contents" class="phone-toc-icon"></Icon>
                        </button>
                    </div>

                    <Transition name="vue-fade">
                        <div v-if="isPhoneToCToggled" class="phone-toc">
                            <div class="phone-toc-head">
                                <div class="phone-toc-title">Table of Contents</div>

                                <button @click="onPhoneTocCloseClick" class="phone-toc-close">
                                    <Icon name="phone-close"></Icon>
                                </button>
                            </div>

                            <div class="phone-toc-body">
                                <ul class="phone-toc-items">
                                    <li
                                        @click="onPhoneTocCloseClick"
                                        v-for="item in menuItems"
                                        :key="item.url"
                                        class="phone-toc-item"
                                    >
                                        <Anchor
                                            :url="item.url"
                                            :class="{ 'is-active': item.isInView }"
                                            class="phone-toc-item-link"
                                        >
                                            {{ item.label }}
                                        </Anchor>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Transition>
                </template>

                <template v-else>
                    <div v-if="title" class="current-chapter">
                        <p class="current-chapter-title">
                            {{ title }}
                        </p>
                    </div>

                    <DropdownMenu
                        v-if="currentChapterTitle"
                        :items="menuItems"
                        :isFullWidth="true"
                        :isLabelLineClamped="true"
                        class="dropdown"
                    >
                        <template #label>
                            <span class="dropdown-label">{{ currentChapterTitle }}</span>
                        </template>

                        <template #dropdownItem="{ item }">
                            <DropdownMenuItem
                                :url="item.url"
                                :class="{ 'is-active': item.isInView }"
                                class="chapter-menu-item"
                            >
                                {{ item.label }}
                            </DropdownMenuItem>
                        </template>
                    </DropdownMenu>
                </template>
            </div>
        </div>

        <div class="progress-bar"></div>
    </nav>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    chapters: {
        type: Array,
        default: () => [],
    },
});

const currentChapterTitle = computed(() => {
    return props.chapters?.find((chapter) => chapter.isInView)?.label || 'Table of Contents';
});

const menuItems = computed(() => {
    return props.chapters?.map((chapter) => {
        return {
            label: chapter.label,
            url: `#${titleToSlug(chapter.label)}`,
            isInView: chapter.isInView,
        };
    });
});

const { y, isScrolling } = useScroll(document);
const windowHeight = useWindowSize().height;
const documentHeight = useElementSize(document.querySelector('body')).height;

const scrollPercentage = computed(() => {
    return y.value / (documentHeight.value - windowHeight.value);
});

const isPhoneToCToggled = ref(false);
function onPhoneTocOpenClick() {
    isPhoneToCToggled.value = true;
}
function onPhoneTocCloseClick() {
    isPhoneToCToggled.value = false;
}
</script>

<style lang="scss" scoped>
.chapters-nav {
    border-top: 0.05rem solid palette(divider);
    background: color(blanc);
    box-shadow: 0 0.6rem 0.8rem 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 60px; //since header no longer goes away
    width: 100%;
    @include z-index(subheader);

    @include media-query(phone) {
        position: fixed;
        bottom: 0;
        top: initial;
        background: transparent;
        border: none;
        box-shadow: none;
    }
}

.wrapper {
    height: 100%;
    @include content-section;

    @include media-query(phone) {
        padding-inline-start: 0;
        padding-inline-end: 0;
        margin-bottom: 2.6rem;
        position: relative;
    }
}

.inner {
    display: flex;
    justify-content: space-between;
    gap: 10%;
    @include content-padding;

    @include media-query(phone) {
        justify-content: flex-end;
        align-items: center;
        gap: 2rem;
    }
}

.current-chapter,
.dropdown {
    padding-top: 2.2rem;
    padding-bottom: 2.2rem;
    flex: 0 1 auto;
}

.current-chapter-title {
    @include text-truncated(2);
}

.dropdown {
    align-self: stretch;
    display: flex;
    justify-content: center;
}

.dropdown-menu {
    min-width: 20%;
}

.dropdown-label {
    color: color(offnoir, default, 0.5);
    @include transition(color, controls);

    &:hover {
        color: color(offnoir, hover, 0.4);
    }
    &:active {
        color: color(offnoir, active);
    }
}

.progress-bar {
    height: 0.4rem;
    width: calc(var(--scroll-progress) * 100%);
    background-color: color(teal);
    transform: translateY(0.2rem);
    transition: width 50ms;

    @include media-query(phone) {
        transform: none;
    }
}

:deep(.items) {
    overflow-y: scroll;
    max-height: 80vh;
}

.chapter-menu-item,
.phone-toc-item-link {
    color: color(blue, dark, 0.6);
    @include transition(color, controls);

    &:hover {
        color: color(blue, dark, 0.4);
    }
    &:active,
    &.is-active {
        color: color(teal, default);
    }

    @include media-query(phone) {
        color: color(blue, dark);
    }
}

.phone-panel-left {
    padding-inline-start: 4.5rem;
    flex: 1;
    display: flex;
    justify-content: center;
}

.phone-toc-open {
    width: 4.5rem;
    height: 4.5rem;
    background-color: color(blanc);
    border-radius: 100rem;
    border: 1px solid #f1f1f1;
    box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.14);
    position: relative;
}

.phone-toc-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.9rem;
}

.phone-toc {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color(blanc);
    overflow-y: scroll;

    @include media-query(phone) {
        top: 70px;
    }
}

.phone-toc-head {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid palette(divider);
    @include content-padding;
}

.phone-toc-item {
    border-bottom: 1px solid palette(divider);
}

.phone-toc-item-link {
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    display: block;
    @include content-padding;
}

.phone-controls {
    padding: 1.2rem 2.4rem;
    display: inline-flex;
    border-radius: 100rem;
    border: 1px solid palette(divider);
    box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.14);
}

.phone-subscribe {
    padding-inline-end: 1.5rem;
    border-inline-end: 1px solid palette(border-light);
    color: color(offnoir);
    opacity: 0.5;
}

.phone-share {
    padding-inline-start: 1.2rem;
}

.phone-share-icon {
    opacity: 0.2;
    flex: 1;
}

.phone-controls {
    background-color: color(blanc);
}
</style>
